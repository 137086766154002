"use client"

import { Button, Col, Form, Row } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { apiPostContactBooking } from '@/api/contact';
import { ContactBookingFormData } from '@/types';

const HomeContactForm = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);
	const [name, setName] = useState<string>('');
	const [guest, setGuest] = useState<string>('');
	const [checkIn, setCheckIn] = useState<string>('');
	const [checkOut, setCheckOut] = useState<string>('');
	const [room, setRoom] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [phone, setPhone] = useState<string>('');
	const [requirement, setRequirement] = useState<string>('');
	const [isClient, setIsClient] = useState(false)
 
	useEffect(() => {
		setIsClient(true)
	}, [])


	const submit = async () => {
		const data: ContactBookingFormData = {
			name,
			guest,
			checkIn,
			checkOut,
			room,
			email,
			phone,
			requirement,
		}
		console.log('submitted', data);

		setLoading(true);
		const response = await apiPostContactBooking(data);
		setLoading(false);
		if (response.status) {
			console.log(response.data);
			setName('');
			setGuest('');
			setCheckIn('');
			setCheckOut('');
			setRoom('');
			setEmail('');
			setPhone('');
			setRequirement('');
		} else {
			alert(response.message);
		}
	}

	return (
		<Row>
			<Col md={2}></Col>
			<Col md={8}>
				<h4 className="text-center">Contact Us</h4>

				{isClient ? (
				<Form className="contact-form">
					<Row className="mb-4">
						<Col>
							<Form.Group>
								<Form.Label>Your name</Form.Label>
								<Form.Control type="text" value={name} onChange={e => setName(e.target.value)} />
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>Number of guests</Form.Label>
								<Form.Select value={guest} onChange={e => setGuest(e.target.value)} >
									{new Array(10).fill(1).map((a, i) => {
										return <option value={(i + 1)} key={i}>{i + 1}</option>
									})}
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col>
							<Form.Group>
								<Form.Label>Check in</Form.Label>
								<Form.Control type="date" value={checkIn} onChange={e => setCheckIn(e.target.value)} />
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>Check Out</Form.Label>
								<Form.Control type="date" value={checkOut} onChange={e => setCheckOut(e.target.value)} />
							</Form.Group>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col>
							<Form.Group>
								<Form.Label>Room</Form.Label>
								<Form.Select value={room} onChange={e => setRoom(e.target.value)}>
									<option value="Standard Room">Standard Room</option>
									<option value="Deluxe Room">Deluxe Room</option>
									<option value="Deluxe Ensuite Room">Deluxe Ensuite Room</option>
									<option value="Queen Room">Queen Room</option>
									<option value="King Room">King Room</option>
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col>
							<Form.Group>
								<Form.Label>Your email</Form.Label>
								<Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} />
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>Your phone</Form.Label>
								<Form.Control type="number" value={phone} onChange={e => setPhone(e.target.value)} />
							</Form.Group>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col>
							<Form.Group>
								<Form.Label>Special requirements</Form.Label>
								<textarea className="form-control" value={requirement} onChange={e => setRequirement(e.target.value)} ></textarea>
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Col className="text-center">
							<Button type="button" onClick={submit} disabled={loading}>Submit</Button>
						</Col>
					</Row>
				</Form>
				) : <></>}
			</Col>
		</Row>
	)
}

export default HomeContactForm;